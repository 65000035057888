<!--
 * @Author: 王鹏
 * @Date: 2020-03-18 14:19:28
 * @LastEditors: 王广婷
 * @LastEditTime: 2020-04-27 20:43:29
 -->
<template>
  <div>
    <div class="top_img">
      <img src="@/assets/xsqd/setBg.png" alt />
    </div>
    <div class="setInfo">
      <div>版本信息</div>
      <div>V1.0.1</div>
    </div>
    <div class="footer" @click="quitLogin">解除绑定并退出登录</div>
  </div>
</template>
<script>
import { xsqdUnbind } from '@/api/xsqd'
import { Dialog, Toast } from 'mand-mobile'
import { getStorage } from '@/lib/util'
export default {
  methods: {
    //清空缓存
    clearInfo() {
      sessionStorage.clear()
      localStorage.clear()
      this.$router.push({ path: '/main' })
    },
    //解绑并退出
    quitLogin() {
      Dialog.confirm({
        content: '是否解除绑定并退出登录？',
        confirmText: '确定',
        onConfirm: () => {
          let salecomid = getStorage('u_s', {}).salecomid
          let saleempno = getStorage('u_s', {}).empno
          let hostname = getStorage('hostname', '')
          xsqdUnbind({ salecomid: salecomid, saleempno: saleempno }).then(
            () => {
              Toast.succeed('解除成功')
              sessionStorage.clear()
              localStorage.clear()
              if (hostname == 'nhwx') {
                this.$router.push({ path: '/login' })
              } else {
                this.$router.push({ path: '/sxlogin' })
              }
            }
          )
        },
      })
    },
  },
}
</script>
<style lang="stylus" scoped>
.top_img {
  // border-bottom : 0.1rem solid #F7F6FB
  background: white;
  padding: 0.37rem 0.32rem 0;
}

.top_img img {
  height: 100%;
  width: 100%;
  margin: auto;
}

.setInfo {
  padding: 0.39rem 0.32rem;
  display: flex;
  justify-content: space-between;
  font-size: 0.4rem;
  font-weight: 400;
  color: #1A1A1A;
}

.footer {
  width: 6.56rem;
  height: 1.11rem;
  line-height: 1.11rem;
  text-align: center;
  background: #FFFFFF;
  border: 2px solid #FF7326;
  border-radius: 1rem;
  margin: 70% auto;
  font-size: 0.43rem;
  color: #FF7326;
}

/deep/ .md-button {
  height: 1.2rem;
}

/deep/ .md-field {
  padding-bottom: 0;
  padding-top: 0;
}
</style>
